import styles from './Shogi.module.scss';
import { Title } from '../common/Title/Title';
import { Paragraph } from '../common/Paragraph/Paragraph';
import { Link } from '../common/Link/Link';
import { ShogiGallery } from './ShogiGallery/ShogiGallery';

export const Shogi = () => {
  return (
    <div id="shogi" className={styles.background}>
      <section className={styles.shogi}>
        <Title className={styles.title}>Shogi, the best chess</Title>

        <Paragraph className={styles.paragraph}>
          Shogi, also known as "japanese chess", is my favorite game for the last 11 years.
          The game number one in Japan is almost unknown in Europe and ten to one is completely new for you,
          so here is a couple of links that can help you to dive in:
        </Paragraph>

        <Paragraph className={styles.paragraph}>
          <ul className={styles.links}>
            <li><Link openInNewTab href="https://en.wikipedia.org/wiki/Shogi">wiki</Link></li>
            <li><Link openInNewTab href="https://www.youtube.com/playlist?list=PL587865CAE59EB84A">videos</Link></li>
            <li><Link openInNewTab href="https://lishogi.org/learn">lishogi&nbsp;course</Link></li>
          </ul>
        </Paragraph>

        <Paragraph className={styles.paragraph}>
          I started to play shogi in 2012. Five years later in 2017 I won first place in European Championship
          and thanks to this I visited International Shogi Forum in Japan that year.
          In 2019 I became European champion for the second time.
          At the moment, I mostly participate in titled tournaments organized in
          shogi club <Link openInNewTab href="https://www.instagram.com/ginkammuri/">Ginkammuri</Link>.
        </Paragraph>

        <ShogiGallery className={styles.gallery} />

        <Paragraph className={styles.paragraph}>
          Shogi definetely is the best chess I played.
          Many chess players who have switched from classic chess to "japanese" one agree with this.
          Masters and grandmasters acquainted with shogi appreciate the beauty of strategy and tactics of this game.
        </Paragraph>
      </section>
    </div>
  );
};
