import styles from './Layout.module.scss';
import { PropsWithChildren } from 'react';

export const Layout = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles.layout}>
      {children}
    </div>
  );
}
