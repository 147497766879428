import styles from './Constellation.module.scss';
import { Fragment, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { animateConstellation, Constellation as IConstellation, getConstellationViewbox } from '../stars.configuration';

interface Props {
  readonly className: string;
  readonly constellation: IConstellation;
}

export const Constellation = ({ constellation, className }: Props) => {
  const [dynamicConstellation, setDynamicConstellation] = useState(constellation);

  const viewbox = useMemo(() => {
    return getConstellationViewbox(constellation).join(' ');
  }, [constellation]);

  useEffect(() => {
    setDynamicConstellation(animateConstellation(constellation));

    const interval = setInterval(() => {
      setDynamicConstellation(animateConstellation(constellation));
    }, 6000);

    return () => clearInterval(interval);
  }, [constellation]);

  return (
    <svg className={classNames(styles.svg, className)} viewBox={viewbox} preserveAspectRatio="none">
      {dynamicConstellation.map(([size, x1, y1, connectedStars], i1) => (
        <Fragment key={i1}>
          <path
            className={styles.star}
            strokeWidth={size}
            d={`M ${x1} ${y1} L ${x1} ${y1}`}
          />
          {
            dynamicConstellation
              .filter((_, i2) => connectedStars.includes(i2 + 1))
              .map(([, x2, y2], i2) => (
                <path
                  key={i2}
                  className={styles.connection}
                  d={`M ${x1} ${y1} L ${x2} ${y2}`}
                />
              ))
          }
        </Fragment>
      ))}
    </svg>
  );
}
