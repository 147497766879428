import styles from './MainScreen.module.scss';
import { BaseInfo } from './BaseInfo/BaseInfo';
import { linkedinLink } from '../../constants/contacts';
import { ForestImage } from './ForestImage/ForestImage';
import { Lagom } from './Lagom/Lagom';
import { Navigation } from './Navigation/Navigation';

export const MainScreen = () => {
  return (
    <section className={styles.mainScreen}>
      <div className={styles.head}>
        <BaseInfo
          fullName="Vincent Tanian"
          qualification={<>frontend&nbsp;developer, shogi&nbsp;player, student</>}
          location="Tokyo, Japan"
          photoLink="/assets/images/my-face.jpg"
          linkedinLink={linkedinLink}
        />
        <Lagom />
      </div>
      <Navigation className={styles.navigation} />
      <ForestImage className={styles.forestImage} />
    </section>
  );
};
