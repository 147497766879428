import { MainScreen } from '../MainScreen/MainScreen';
import { AboutMe } from '../AboutMe/AboutMe';
import { DevExperience } from '../DevExperience/DevExperience';
import { Shogi } from '../Shogi/Shogi';
import { PetProjects } from '../PetProjects/PetProjects';

export const Profile = () => {
  return (
    <>
      <MainScreen />
      <AboutMe />
      <DevExperience />
      <Shogi />
      <PetProjects />
    </>
  );
};
