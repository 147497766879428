export type Star = [
  size: number,
  x: number,
  y: number,
  connectedStars: number[],
];
export type Constellation = Star[];

export const lonelyStars: Constellation =
  Array.from({ length: 60 }).map(() => [
    Math.random() * 2 + 1,
    Math.random() * 100,
    Math.random() * 100,
    [],
  ]);

export const constellations: Constellation[] = [
  [
    [4, 26, 34, [2]],
    [5, 25, 27, [1, 3]],
    [4, 19, 17, [2, 4]],
    [5, 12, 11, [3, 5, 6, 7]],
    [4, 7, 0, [4, 6, 8]],
    [5, 6, 8, [4, 5, 7, 8]],
    [4, 6, 16, [4, 6, 8]],
    [5, 0, 6, [5, 6, 7]],
  ],
  [
    [5, 24, 11, [2, 3]],
    [4, 16, 24, [1, 3]],
    [5, 40, 17, [1, 2]],
    [3, 10, 50, [2]],
    [5, 60, 15, [3, 6]],
    [4, 70, 17, [5]],
    [4, 20, 87, [4]],
    [3, 50, 91, [7]],
  ],
  [
    [3, 0, 14, [2]],
    [4, 20, 11, [1, 3, 4]],
    [3, 26, 0, [2]],
    [3, 23, 13, []]
  ],
]

export const animateConstellation = (initialConstellation: Constellation): Constellation =>
  initialConstellation.map(([size, x, y, connectedStars]) => [
    size,
    x + Math.random() * 2 - 1,
    y + Math.random() * 2 - 1,
    connectedStars,
  ]);

export const getConstellationViewbox = (constellation: Constellation): [number, number, number, number] =>
  [
    Math.min(...constellation.map(([, x]) => x)),
    Math.min(...constellation.map(([, , y]) => y)),
    Math.max(...constellation.map(([, x]) => x)),
    Math.max(...constellation.map(([, , y]) => y)),
  ];
