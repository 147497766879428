import { PhotoProvider, PhotoView } from 'react-photo-view';
import styles from './ShogiGallery.module.scss';
import classNames from 'classnames';

import shogiImage1 from '../images/shogi-1.jpg';
import shogiImage2 from '../images/shogi-2.jpg';
import shogiImage3 from '../images/shogi-3.jpg';
import shogiImage4 from '../images/shogi-4.jpg';
import shogiImage5 from '../images/shogi-5.jpg';
import shogiImageThumbnail1 from '../images/shogi-1-thumbnail.jpg';
import shogiImageThumbnail2 from '../images/shogi-2-thumbnail.jpg';
import shogiImageThumbnail3 from '../images/shogi-3-thumbnail.jpg';
import shogiImageThumbnail4 from '../images/shogi-4-thumbnail.jpg';
import shogiImageThumbnail5 from '../images/shogi-5-thumbnail.jpg';

const images = [
  [shogiImage1, shogiImageThumbnail1],
  [shogiImage2, shogiImageThumbnail2],
  [shogiImage3, shogiImageThumbnail3],
  [shogiImage4, shogiImageThumbnail4],
  [shogiImage5, shogiImageThumbnail5],
];

interface Props {
  readonly className: string;
}

export const ShogiGallery = ({ className }: Props) => {
  const onVisibleChange = (visible: boolean) => {
    document.body.style.paddingRight = visible ? 'var(--scrollbar-width)' : '';
  };

  return (
    <PhotoProvider bannerVisible={true} maskOpacity={0.95} onVisibleChange={onVisibleChange}>
      <ul className={classNames(className, styles.gallery)}>
        {images.map(([fullImage, thumbnail]) => (
          <li key={fullImage}>
            <PhotoView src={fullImage}>
              <img src={thumbnail} alt=""/>
            </PhotoView>
          </li>
        ))}
      </ul>
    </PhotoProvider>
  );
};
