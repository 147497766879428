import { ReactNode } from 'react';
import styles from './SummaryLabelValue.module.scss';

interface Props {
  readonly label: string;
  readonly value: ReactNode;
}

  export const SummaryLabelValue = ({ label, value }: Props) => {
  return (
    <div className={styles.container}>
      <span className={styles.value}>{value}</span>
      <span className={styles.label}>{label}</span>
    </div>
  );
}
