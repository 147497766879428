import styles from './StarSky.module.scss';
import { Constellation } from './Constellation/Constellation';
import { lonelyStars, constellations } from './stars.configuration';

export const StarSky = () => {
  return (
    <>
      {constellations.map((constellation, index) => (
        <Constellation key={index} className={styles.constellation} constellation={constellation} />
      ))}
      <Constellation className={styles.lonelyStars} constellation={lonelyStars} />
    </>
  )
};
