import styles from './Navigation.module.scss';
import classNames from 'classnames';
import { Link } from '../../common/Link/Link';

interface Props {
  readonly className: string;
}

const anchors = [
  '#about-me',
  '#frontend',
  '#shogi',
  '#pet-projects',
];

export const Navigation = ({ className }: Props) => {
  return (
    <nav className={classNames(styles.navigation, className)}>
      {anchors.map(anchor => (
        <Link key={anchor} href={anchor} className={styles.anchor}>{anchor}</Link>
      ))}
    </nav>
  );
};
