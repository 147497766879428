import styles from './AboutMe.module.scss';
import { Title } from '../common/Title/Title';
import { Paragraph } from '../common/Paragraph/Paragraph';
import { StarSky } from '../StarSky/StarSky';

export const AboutMe = () => {
  return (
    <section id="about-me" className={styles.aboutMe}>
      <StarSky />
      <Title className={styles.title}>About me</Title>
      <Paragraph className={styles.paragraph}>
        Hey, welcome! My name is <strong>Vincent</strong> and I'm 25.
      </Paragraph>
      <Paragraph className={styles.paragraph}>
        Mostly I'm passionate about frontend development and any other brainstorm:
        I'm keen on <strong>shogi</strong>, solving rubik's cube, collecting japanese huzzles and so on.
      </Paragraph>
      <Paragraph className={styles.paragraph}>
        In the spring of 2024 I turned my life around and moved
        to <strong>Tokyo</strong> to study <strong>Japanese</strong> at a language school.
        I'm currently preparing to take the <strong>JLPT N2</strong> level this winter.
        And of course, I'm enjoying Japan.
      </Paragraph>
      <Paragraph className={styles.paragraph}>
        I also know <strong>French</strong> a little and slightly speak <strong>English</strong>.
        I studied French for 11 years at school, but now I don't remember much of it.
        English is the language I use in my daily student life and sometimes at work.
      </Paragraph>
    </section>
  );
}
