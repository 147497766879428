import { PropsWithChildren } from 'react';
import styles from './FrameworkSection.module.scss';
import { SummaryLabelValue } from './SummaryLabelValue/SummaryLabelValue';
import Marquee from 'react-fast-marquee';
import classNames from 'classnames';

interface Props {
  readonly className?: string;
  readonly name: string;
  readonly experience: string;
  readonly projects: string;
  readonly tools: string[];
}

export const FrameworkSection = ({
  className,
  name,
  experience,
  projects,
  tools,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <section className={classNames(styles.section, className)}>
      <div className={styles.frameworkName}>{name}</div>
      <div className={styles.summary}>
        <SummaryLabelValue label="Experience" value={experience} />
        <SummaryLabelValue label="Projects" value={projects} />
      </div>
      <div className={styles.content}>{children}</div>
      <Marquee className={styles.toolsMarquee} speed={75}>
        {tools.map((tool) => (
          <span className={styles.tool} key={tool}>{tool}</span>
        ))}
      </Marquee>
    </section>
  );
}
