import { Layout } from './components/Layout/Layout';
import { Profile } from './components/Profile/Profile';
import { useScrollbarWidth } from './hooks/use-scrollbar-width';

function App() {
  useScrollbarWidth();

  return (
    <Layout>
      <Profile />
    </Layout>
  );
}

export default App;
