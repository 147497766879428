import styles from './PetProjects.module.scss';
import { Title } from '../common/Title/Title';
import { Paragraph } from '../common/Paragraph/Paragraph';
import { Link } from '../common/Link/Link';

import shogiScoreTrackerIcon from './images/shogi-score-tracker-icon.png';

export const PetProjects = () => {
  return (
    <section id="pet-projects" className={styles.petProjects}>
      <Title className={styles.title}>My pet projects</Title>
      <Paragraph>
        The main advantage of being developer for me has always been the opportunity to create anything —
        that would help you or, as you fell, your society — by your own hands.
      </Paragraph>
      <Paragraph>
        I started to use my modest at that time skills in the first course of university, about 7&nbsp;years ago.
        My two very first projects I worked that year, they are not so powerful,
        and although the first one is probably outmoded I dream to rewrite the second one.
        Here they are:
      </Paragraph>
      <div className={styles.firstProjects}>
        <Link openInNewTab href="https://nihon-test-33499.web.app/">Japanese word memorizer</Link>
        <Link openInNewTab href="https://shogiplanet.web.fc2.com/">Shogi article collection</Link>
      </div>
      <Paragraph>
        Next several years I was focused on education and wasn't developing anything for my own needs,
        but the last year I had fun with
        this <Link openInNewTab href="https://labeeerinth.web.app/" className={styles.labyrinthLink}>labyrinth</Link> and
        started an important project for me, which is calculating personal score in shogi:
      </Paragraph>
      <Link openInNewTab href="https://shogi-scoring.web.app/" className={styles.shogiScoreTrackerButton}>
        <img src={shogiScoreTrackerIcon} alt="" />
        Shogi Score Tracker
      </Link>
    </section>
  );
};
