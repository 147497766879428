import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './BaseInfo.module.scss';
import { Link } from '../../common/Link/Link';

interface Props {
  readonly className?: string;
  readonly fullName: string;
  readonly qualification: ReactNode;
  readonly location: string;
  readonly photoLink: string;
  readonly linkedinLink: string;
}

const linkedinIconLink = '/assets/icons/linkedin.png';

export const BaseInfo = ({ className, fullName, qualification, location, photoLink, linkedinLink }: Props) => {
  return (
    <section className={classNames(styles.container, className)}>
      <img className={styles.photo} src={photoLink} alt="My face should be here :)" />
      <div className={styles.description}>
        <span className={styles.name}>
          {fullName}
          <Link href={linkedinLink} openInNewTab>
            <img className={styles.linkedinIcon} src={linkedinIconLink} alt="LinkedIn"/>
          </Link>
        </span>
        <span className={styles.qualification}>{qualification}</span>
        <span className={styles.location}>{location}</span>
      </div>
    </section>
  );
}
