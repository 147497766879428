import styles from './Lagom.module.scss';

export const Lagom = () => {
  return (
    <span className={styles.lagom}>
      <strong>Lagom</strong> <em>(swedish word)</em> —
      <div>not too much, not too little.</div>
      <div>just the right amount.</div>
    </span>
  );
};
