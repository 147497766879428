import styles from './ForestImage.module.scss';
import classNames from 'classnames';

import forest3072avif from './images/forest-3072.avif';
import forest1920avif from './images/forest-1920.avif';
import forest1600avif from './images/forest-1600.avif';
import forest1366avif from './images/forest-1366.avif';
import forest1024avif from './images/forest-1024.avif';
import forest768avif from './images/forest-768.avif';
import forest3072 from './images/forest-3072.png';
import forest1920 from './images/forest-1920.png';
import forest1600 from './images/forest-1600.png';
import forest1366 from './images/forest-1366.png';
import forest1024 from './images/forest-1024.png';
import forest768 from './images/forest-768.png';
import forest640 from './images/forest-640.png';

interface Props {
  readonly className?: string;
}

export const ForestImage = ({ className }: Props) => {
  return (
    <div className={classNames(styles.imageContainer, className)}>
      <div className={styles.gradient} />
      <picture>
        <source
          type="image/avif"
          srcSet={[
            `${forest3072avif} 3072w`,
            `${forest1920avif} 1920w`,
            `${forest1600avif} 1600w`,
            `${forest1366avif} 1366w`,
            `${forest1024avif} 1024w`,
            `${forest768avif} 768w`,
          ].join(', ')}
        />
        <img
          alt=""
          srcSet={[
            `${forest3072} 3072w`,
            `${forest1920} 1920w`,
            `${forest1600} 1600w`,
            `${forest1366} 1366w`,
            `${forest1024} 1024w`,
            `${forest768} 768w`,
            `${forest640} 768w`,
          ].join(', ')}
        />
      </picture>
    </div>
  );
};
